import React, { FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import classNames from "classnames"
import { PodcastShowSocialLinks } from "../../podcast-types"
import {
  PodcastSocialLink,
  PodcastSocialLinkProps
} from "./podcast-social-link"
import { socialLinks } from "./podcast-show-link-constants"
import styles from "./podcast-show-links.module.scss"

export interface PodcastSocialLinksProps extends PodcastShowSocialLinks {
  showTitle: string
}

export const PodcastSocialLinks: FunctionComponent<PodcastSocialLinksProps> = (
  props
) => {
  const links: PodcastSocialLinkProps[] = socialLinks.map((link) => ({
    href: props[link.key] || "",
    ...link
  }))

  return (
    <div className={classNames(styles.linkContainer, styles.socialLinks)}>
      <h3 className={styles.linkTitle}>
        <FormattedMessage
          id="podcasts-social-links-title"
          values={{ show: props.showTitle }}
        />
      </h3>
      <ul className={styles.linkList}>
        {links.map((link, index) =>
          link.href ? (
            <li className={styles.linkItem} key={index}>
              <PodcastSocialLink {...link} />
            </li>
          ) : null
        )}
      </ul>
    </div>
  )
}
