import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { PodcastShow } from "../../podcast-types"
import { PodcastListenLinks } from "./podcast-listen-links"
import { PodcastSocialLinks } from "./podcast-social-links"
import styles from "./podcast-show-links.module.scss"

export interface PodcastShowLinksProps {
  show: PodcastShow
}

export const PodcastShowLinks: FunctionComponent<PodcastShowLinksProps> = ({
  show
}) => {
  const listenKeys = Object.keys(show.listenLinks)
  const socialKeys = Object.keys(show.socialLinks)

  if (listenKeys.length === 0 && socialKeys.length === 0) return null

  return (
    <section
      className={classNames(
        styles.mainContainer,
        (!socialKeys.length || !listenKeys.length) && styles.flexContainer
      )}
    >
      {!!listenKeys.length && <PodcastListenLinks {...show.listenLinks} />}
      {!!socialKeys.length && (
        <PodcastSocialLinks showTitle={show.title} {...show.socialLinks} />
      )}
    </section>
  )
}
