import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { Icon, IconVariant } from "../../../../components/icon/icon"
import styles from "./podcast-show-links.module.scss"

export interface PodcastSocialLinkProps {
  href: string
  icon: IconVariant
  title: string
}

export const PodcastSocialLink: FunctionComponent<PodcastSocialLinkProps> = ({
  icon,
  href,
  title
}) => (
  <a
    className={classNames(styles.socialLink, styles[title.toLowerCase()])}
    title={title}
    href={href}
    target="_blank"
    rel="noreferrer"
  >
    <Icon className={styles.socialIcon} variant={icon} />
  </a>
)
