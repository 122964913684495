import React, { FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import { Icon } from "../../../../components/icon/icon"
import { PodcastRecentEpisode } from "../../podcast-types"
import styles from "./podcast-recent-episodes.module.scss"
import { Link } from "@components/link/link"
import { Button } from "@components/button/button"
import { PathPrefix } from "@core/constants"
import { Markdown } from "@components/markdown/markdown"

export interface PodcastRecentEpisodesProps {
  episodes: PodcastRecentEpisode[]
  showViewMore: boolean
  showSlug: string
}

export const PodcastRecentEpisodes: FunctionComponent<
  PodcastRecentEpisodesProps
> = ({ episodes, showViewMore, showSlug }) => (
  <section className={styles.container}>
    <div className={styles.contentWrapper}>
      <h3 className={styles.title}>
        <FormattedMessage id="podcasts-recent-episodes" />
      </h3>
      <ul className={styles.episodes}>
        {episodes.map((episode, index) => (
          <li className={styles.episode} key={index}>
            <Link className={styles.episodeLink} to={episode.link}>
              <h4 className={styles.episodeTitle}>{episode.title}</h4>
              <div className={styles.episodeMeta}>
                {(episode.formattedDate ||
                  episode.formattedSeasonAndEpisode) && (
                  <div className={styles.episodeInfo}>
                    {episode.formattedSeasonAndEpisode || episode.formattedDate}
                  </div>
                )}
                {episode.meta && (
                  <div className={styles.meta}>{episode.meta}</div>
                )}
                <Icon className={styles.episodeIcon} variant="12-audio" />
              </div>
              {episode.description && (
                <Markdown
                  className={styles.episodeDescription}
                  content={episode.description}
                />
              )}
            </Link>
          </li>
        ))}
      </ul>
      {showViewMore && (
        <div className={styles.seeMoreEpisodes}>
          <Button
            variant="secondary"
            size="sm"
            to={`${PathPrefix.PodcastsIndex}/${showSlug}`}
          >
            See more episodes
          </Button>
        </div>
      )}
    </div>
  </section>
)
